<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-addNewCategory" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header
            right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Add New Category :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="addUsercategory">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">

                        <b-form-group id="display_name" label="Display Name:" label-for="diplay_name">
                            <validation-provider #default="{ errors }" name="display_name" rules="required">
                                <b-form-input id="display_name" v-model="form.display_name" @input="onChangeName()"
                                    placeholder="Display Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="name" label="Name:" label-for="input-name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input style="pointer-events: none;" id="input-name" v-model="form.name" placeholder="Enter Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Submit
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import User from '../../../Helpers/User'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, name, display_name,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'


export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent, BFormCheckboxGroup
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            required, name, display_name,
            form: {
                id: '',
                name: '',
                display_name: '',
            },
        }
    },
    methods: {

        checkPermission(permission) {

            return User.checkPermission(permission)
        },
        slugify(str) {
            str = str.replace(/^\s+|\s+$/g, '');
            str = str.toLowerCase();


            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to = "aaaaeeeeiiiioooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, '')
                .replace(/\s+/g, '_')
                .replace(/-+/g, '_');

            return str;
        },

        onChangeName() {
            this.form.name = this.slugify(this.form.display_name)
            console.log(this.slugify(this.form.display_name))
        },

        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        clearForm() {
            this.form.name = '',
                this.form.display_name = ''
        },
        onSubmit(event) {
            this.$refs.addUsercategory.validate().then(success => {
                if (success) {
                    try {
                        axios({
                            method: 'post',
                            url: process.env.VUE_APP_API + '/category',
                            data: {
                                'name': this.form.name,
                                'display_name': this.form.display_name,
                            }
                        })
                            .then(res => {
                                if (res.data.success) {
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: res.data.message,
                                                icon: 'AlertOctagonIcon',
                                                text: '',
                                                variant: 'success',
                                            },
                                        },
                                            {
                                                position: 'top-right'
                                            })
                                    this.$emit('refresh')
                                    this.closeSidebar()
                                }
                                else {
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Failed',
                                                icon: 'AlertOctagonIcon',
                                                text: res.data.success,
                                                variant: 'danger',
                                            },
                                        },
                                            {
                                                position: 'top-left'
                                            })
                                }
                            })
                    }
                    catch (error) {
                        console.log(error)
                    }
                }

            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-addNewCategory')
            this.clearForm()
        },
    },
    computed: {
    },
};


</script>
